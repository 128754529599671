import { ApolloError } from '@apollo/client';
import type { AxiosInstance } from 'axios';
import { addItemsToCart } from '@ecomm/cart/api/createCartItems';
import { getErrorCode as getAddToCartErrorCode } from '@ecomm/cart/models/AddToCartErrors';
import {
  hasGiftInformation,
  getCountryCart,
  shouldAutoAddSubForLineItem,
} from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import { ShopCartUpdateActionType } from '@ecomm/graphql/types.generated';
import logAction from '@ecomm/logging/logAction';
import type { CtCartFragment } from '@ecomm/shop-cart/graphql/fragments.generated';
import { getSubscriptionId } from '@ecomm/shop/graphql/addToCartResolver';

import type { Bundle, Product, MonolithPayload } from './types';

export const convertCurrentCTCartToMonolith = async (
  shopCart: any,
  updateCartMutation: any,
  setIsGiftMutation: any,
  addPromoCodeMutation: any,
  restClient: AxiosInstance,
  apolloClient: any,
  ctClient: any,
  isProjectPhoenixEnabled: boolean,
  isCommercetoolsWWWAtcEnabled: boolean,
) => {
  const subscriptionProductOptionId = await getSubscriptionId(
    apolloClient,
    ctClient,
    isCommercetoolsWWWAtcEnabled,
  );
  const monolithCartPayLoad = convertCtToMonolithCart(
    shopCart,
    subscriptionProductOptionId,
  );
  logAction('adding to monolith cart');
  const { data, error } = await addAllItemsToMonolithCart(
    monolithCartPayLoad,
    restClient,
    shopCart,
    updateCartMutation,
  );

  await setIsGift(data, shopCart, setIsGiftMutation, isProjectPhoenixEnabled);
  await addCouponsToMonolithCart(
    data?.id ?? '',
    shopCart,
    addPromoCodeMutation,
    isProjectPhoenixEnabled,
  );

  return { data, error };
};

export const convertCtToMonolithCart = (
  shopCart: any,
  subscriptionProductOptionId: string | undefined,
) => {
  const products: Product[] = [];
  const bundles: Bundle[] = [];

  (shopCart?.lineItems ?? []).forEach((lineItem: any) => {
    if (!shopCart?.isGift && shouldAutoAddSubForLineItem(lineItem)) {
      if (subscriptionProductOptionId) {
        products.push({
          product_option_id: subscriptionProductOptionId,
          quantity: lineItem.quantity,
        });
      } else {
        logAction('Could not add subscription to cart', { shopCart });
      }
    }

    if (lineItem.productVariant?.type === 'Bundle') {
      bundles.push(getBundleItemPayload(lineItem));
    } else {
      products.push({
        product_option_id: lineItem.productVariant?.legacyOptionId,
        quantity: lineItem.quantity,
      });
    }
  });

  const monolithPayload: MonolithPayload = {
    renders_light_cart: true,
  };
  if (products.length) {
    monolithPayload.products = products;
  }
  if (bundles.length) {
    monolithPayload.bundles = bundles;
  }
  return monolithPayload;
};

export const removeCTItemsAfterMonolithConversion = (
  shopCart: any,
  updateCartMutation: any,
) => {
  const actions: any = [];

  (shopCart?.lineItems || []).forEach((lineItem: any) => {
    if (lineItem.productVariant?.type === 'Bundle') {
      actions.push({
        removeBundleItem: {
          lineItemId: lineItem.id,
        },
        actionType: ShopCartUpdateActionType.RemoveBundleItem,
      });
    } else {
      actions.push({
        removeLineItem: {
          lineItemId: lineItem.id,
          quantity: lineItem?.quantity ?? 1,
        },
        actionType: ShopCartUpdateActionType.RemoveLineItem,
      });
    }
  });

  // reset isGift on CT cart back to false after conversion
  if (shopCart?.isGift) {
    actions.push({
      setIsGift: {
        isGift: false,
      },
      actionType: ShopCartUpdateActionType.SetIsGift,
    });

    if (hasGiftInformation(shopCart)) {
      actions.push({
        setGiftInformation: {
          giftMessage: '',
          gifterName: '',
          recipientEmail: '',
          recipientName: '',
        },
        actionType: ShopCartUpdateActionType.SetGiftInformation,
      });
    }
  }

  const { error } = updateCartMutation({
    variables: {
      input: {
        country: getCountryCart(),
        actions: actions,
      },
    },
  });
  if (error) {
    console.error('buyFlowMigration: clearing CT items after conversion failed', error);
  }
};

type MutationResult = {
  loading: boolean;
  data: any;
  error: any;
};

export const addAllItemsToMonolithCart = async (
  cartItems: any,
  restClient: AxiosInstance,
  data?: any,
  updateCartMutation?: any,
) => {
  const result: MutationResult = { loading: false, data: undefined, error: undefined };
  try {
    const cartResults = await addItemsToCart(restClient, cartItems);
    logAction('successful CT to monolith cart conversion');
    result.data = cartResults;
    if (data?.lineItems?.length) {
      removeCTItemsAfterMonolithConversion(data, updateCartMutation);
    }
  } catch (e) {
    const errorCode = e.networkError?.response?.data?.errorCode;
    const errorMsg = getAddToCartErrorCode(errorCode);
    console.error('buyFlowMigration: failed conversion:', e, { errorMsg, errorCode });
    result.error = new ApolloError({ errorMessage: errorMsg });
  }
  return result;
};

const getBundleItemPayload = (lineItem: {
  productVariant: { legacyProductId: any };
  bundleItemLineItems: any;
}) => {
  const bundleItem: Bundle = {
    bundle_id: lineItem.productVariant?.legacyProductId,
    product_options: [],
  };
  (lineItem.bundleItemLineItems || []).forEach((bundleLineItem: any) => {
    Array(bundleLineItem.quantity)
      .fill(0)
      .forEach(() =>
        bundleItem.product_options.push(bundleLineItem?.productVariant?.legacyOptionId),
      );
  });

  return bundleItem;
};

export const setIsGift = async (
  data: any,
  shopCart: any,
  setIsGiftMutation: any,
  isProjectPhoenixEnabled: boolean,
) => {
  try {
    await setIsGiftMutation({
      variables: {
        cartId: data?.id ?? '',
        isGift: !!shopCart?.isGift,
        calculateEstimatedShippingPrice: isProjectPhoenixEnabled,
      },
      optimisticResponse: {
        setIsGiftOnCart: {
          id: data?.id ?? '',
          __typename: 'CartNext',
          isGift: !!shopCart?.isGift,
        } as any,
      },
    });
  } catch (e) {
    console.error(e);
  }
};

const addCouponsToMonolithCart = async (
  monolithCartId: string,
  shopCart: CtCartFragment,
  addPromoCodeMutation: any,
  isProjectPhoenixEnabled: boolean,
) => {
  for (const discount of shopCart?.discounts ?? []) {
    try {
      // CT auto-apply coupons do not have a code, and will fail conversion
      if (discount.discountCode.code) {
        const response = await addPromoCodeMutation({
          variables: {
            couponCode: discount.discountCode.code,
            cartId: monolithCartId,
            calculateEstimatedShippingPrice: isProjectPhoenixEnabled,
          },
        });

        if (response.data?.addCouponToCart?.__typename === 'DomainError') {
          logAction('error converting CT discount to monolith coupon', {
            shopCartData: shopCart,
            discountCode: discount.discountCode.code,
          });
        } else {
          logAction('successful conversion of CT discount to monolith coupon');
        }
      }
    } catch (e) {
      logAction('error converting CT discount to monolith coupon', {
        shopCartData: shopCart,
        discountCode: discount.discountCode.code,
      });
    }
  }
};
