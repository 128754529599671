import { useApolloClient } from '@apollo/client';
import { useAddPromoCodeMutation } from '@ecomm/cart-next/graphql/mutations/AddPromoCode.generated';
import { useSetIsGiftMutation } from '@ecomm/checkout/hooks/setIsGiftOnCart.generated';
import { toCommercetoolsClient } from '@ecomm/commercetools/apollo';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import logAction from '@ecomm/logging/logAction';
import { convertCurrentCTCartToMonolith } from '@ecomm/pg-checkout-commercetools/utils/convertCurrentCTCartToMonolith';
import type { GetShopCartJupiterQuery } from '@ecomm/shop-cart/graphql/queries/ShopCart.generated';
import { useRestClientAndUpdateMutation } from './useRestClientAndUpdateMutation';

/**
 * Hook to convert the CT cart to the Monolith cart.
 * @param setError - Optional function to set error state.
 * @returns An object containing the `convertCTCart` function.
 */
export const useConvertCTCart = (setError?: Function) => {
  const { restClient, updateCartMutation } = useRestClientAndUpdateMutation();
  const apolloClient = useApolloClient();
  const ctClient = toCommercetoolsClient();
  const [setIsGiftMutation] = useSetIsGiftMutation();
  const [addPromoCodeMutation] = useAddPromoCodeMutation();
  const isToggleActive = useIsToggleActive();
  const isProjectPhoenixEnabled = isToggleActive('projectPhoenix');
  const isCommercetoolsWWWAtcEnabled = isToggleActive('commercetools_www_atc_migration');

  /**
   * Converts the CT cart to the Monolith cart.
   * @param params - The context and refetched CT cart data.
   */
  const convertCTCart = async ({
    context,
    refetchedCTCart,
  }: {
    context: Record<string, any>;
    refetchedCTCart: GetShopCartJupiterQuery;
  }) => {
    logAction('converting shopCart items', context);
    const { error } = await convertCurrentCTCartToMonolith(
      refetchedCTCart.shopCart,
      updateCartMutation,
      setIsGiftMutation,
      addPromoCodeMutation,
      restClient,
      apolloClient,
      ctClient,
      isProjectPhoenixEnabled,
      isCommercetoolsWWWAtcEnabled,
    );
    if (error) {
      // Monolith conversion failed
      logAction('error converting shopCart items', {
        refetchedShopCart: refetchedCTCart.shopCart,
      });
      setError?.(error);
      console.error('buyFlowMigration: failed conversion', error);
    }
  };

  return {
    convertCTCart,
  };
};
