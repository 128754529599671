import type { ApolloClient } from '@apollo/client';
import { head } from 'ramda';
import type { Client } from '@peloton/api';
import { useApq as shouldUseApq } from '@peloton/auth/config';
import { toLocaleFromTLD } from '@peloton/internationalize/models/locale';
import { toStoreSlug } from '@peloton/stores';
import { addItemsToCart } from '@ecomm/cart/api/createCartItems';
import type { LocalResolvers, Types } from '@ecomm/graphql';
import { fromLocale, toCurrencyfromLocale } from '@ecomm/graphql-bridge';
import { RENTAL_SLUGS } from '@ecomm/rentals/models';
import { getCtSubscriptionProductVariantId } from '@ecomm/shop/commercetools/getCtSubscriptionProductVariantId';
import { SUBSCRIPTION_SLUG } from '@ecomm/shop/models/Product';
import type { ProductSelection } from '@ecomm/shop/models/Selections';
import type { BundleProductVariantsBySelectionsQuery } from './BundleProductVariantsBySelections.generated';
import { BundleProductVariantsBySelectionsDocument } from './BundleProductVariantsBySelections.generated';
import type { SubscriptionProductBySlugQuery } from './subscriptionProductBySlugQuery.generated';
import { SubscriptionProductBySlugDocument } from './subscriptionProductBySlugQuery.generated';
import type { Variables as BundleVariables } from './useAddBundleToCartMutation';
import type { Variables as PackageVariables } from './useAddPackageToCartMutation';
import type { Variables as ProductVariables } from './useAddProductToCartMutation';
import type { VariantsBySelectionsQuery } from './VariantsBySelections.generated';
import { VariantsBySelectionsDocument } from './VariantsBySelections.generated';

export type Context = {
  apolloClient: ApolloClient<any>;
  restClient: Client;
  ctClient: ApolloClient<any>;
  isCommercetoolsWWWAtcEnabled: boolean;
};

export const addProductToCart: Types.Resolver<{}, {}, Context, ProductVariables> = async (
  _,
  variables,
  { apolloClient, restClient },
) => {
  const response = await apolloClient.query<VariantsBySelectionsQuery>({
    query: VariantsBySelectionsDocument,
    context: { useApq: shouldUseApq() },
    variables,
  });

  const cart = await addItemsToCart(restClient, {
    products: [
      {
        productOptionId: response?.data?.catalog?.variantsBySelections?.[0].id ?? '',
        quantity: 1,
      },
    ],
  });

  return cart;
};

export const addBundleToCart: Types.Resolver<{}, {}, Context, BundleVariables> = async (
  _,
  { slug, locale, currency, bundleId, productSelections, bundleSlug },
  { apolloClient, restClient },
) => {
  const bundleProductVariantResponse = await apolloClient.query<BundleProductVariantsBySelectionsQuery>(
    {
      query: BundleProductVariantsBySelectionsDocument,
      context: { useApq: shouldUseApq() },
      variables: {
        slug,
        locale,
        currency,
        productSelections,
        bundleSlug,
      },
    },
  );

  const productOptionIds = bundleProductVariantResponse?.data.catalog.bundleProductVariantsBySelections?.map(
    (variant: Types.ProductVariant) => variant.id,
  );

  if (productOptionIds == null) return;

  const cart = await addItemsToCart(restClient, {
    bundles: [
      {
        bundleId,
        productOptions: productOptionIds,
      },
    ],
  });

  return cart;
};

export const getProductOptionIdsForKronosPackage = async (
  apolloClient: ApolloClient<any>,
  bundleSlug: string,
  slug: string,
  locale: string,
  currency: string,
  productSelections: ProductSelection[],
) => {
  const bundleProductVariantQuery = apolloClient.query<BundleProductVariantsBySelectionsQuery>(
    {
      query: BundleProductVariantsBySelectionsDocument,
      context: { useApq: shouldUseApq() },
      variables: {
        slug,
        locale,
        currency,
        productSelections,
        bundleSlug,
      },
    },
  );
  const response = await bundleProductVariantQuery;

  return response?.data.catalog.bundleProductVariantsBySelections?.map(
    (variant: Types.ProductVariant) => variant.id,
  );
};
export const getSubscriptionId = async (
  apolloClient: ApolloClient<object>,
  ctClient: ApolloClient<object>,
  isCommercetoolsWWWAtcEnabled: boolean,
) => {
  const baseLocale = toLocaleFromTLD();
  const locale = fromLocale(baseLocale);
  const currency = toCurrencyfromLocale(baseLocale);
  const slug = toStoreSlug();
  return await getSubscriptionIdForKronosPackage(
    apolloClient,
    ctClient,
    slug,
    locale,
    currency,
    isCommercetoolsWWWAtcEnabled,
  );
};
export const getSubscriptionIdForKronosPackage = async (
  apolloClient: ApolloClient<any>,
  ctClient: ApolloClient<any>,
  slug: string,
  locale: string,
  currency: string,
  isCommercetoolsWWWAtcEnabled: boolean = false,
): Promise<string | undefined> => {
  let subscriptionProductId;
  if (isCommercetoolsWWWAtcEnabled) {
    subscriptionProductId = await getCtSubscriptionProductVariantId(
      ctClient,
      SUBSCRIPTION_SLUG,
      locale,
      currency,
    );
  } else {
    const subscriptionQuery = apolloClient.query<SubscriptionProductBySlugQuery>({
      query: SubscriptionProductBySlugDocument,
      context: { useApq: true },
      variables: {
        product: SUBSCRIPTION_SLUG,
        slug,
        locale,
        currency,
      },
    });
    const subsQueryResponse = await subscriptionQuery;

    if (subsQueryResponse) {
      const subscription = subsQueryResponse?.data.catalog.productBySlug;

      // We'd ideally throw the subscriptionResponse.errors here,
      // but that error gets swallowed without raising an OOPS on the client!
      // So, a simple return works just as well
      if (subscription == null) return;

      const subscriptionVariantResponse = await apolloClient.query({
        query: VariantsBySelectionsDocument,
        context: { useApq: true },
        variables: {
          slug,
          locale,
          currency,
          productSelections: [
            {
              product: SUBSCRIPTION_SLUG,
              selections: subscription.attributes.map(
                ({
                  slug: subscriptionSlug,
                  options,
                }: {
                  slug: string;
                  options: Array<any>;
                }) => ({
                  attribute: subscriptionSlug,
                  option: head(options)?.slug,
                }),
              ),
            },
          ],
        },
      });
      subscriptionProductId =
        subscriptionVariantResponse.data.catalog.variantsBySelections[0].id;
    }
  }

  return subscriptionProductId;
};

export const addPackageToCart: Types.Resolver<{}, {}, Context, PackageVariables> = async (
  _,
  {
    slug,
    locale,
    currency,
    packageId,
    hasTradeIn,
    productSelections,
    warrantySelection,
    bundleSlug,
    upsellIds,
    upsellBundles,
    productOptionIds,
    subscriptionId,
  },
  { apolloClient, restClient, ctClient, isCommercetoolsWWWAtcEnabled },
) => {
  // do not auto-add subscriptions for these bundles
  const denylist = [
    'guide-strength-starter-us',
    'guide-strength-starter-md-22-us',
    'guide-strength-starter-md-22-ca',
    'guide-strength-starter-ca',
    'guide-strength-starter-uk',
    'guide-strength-starter-au',
    'guide-power-us',
    'guide-power-ca',
    'guide-md-22-us',
    'guide-md-22-ca',
    'guide-us',
    'guide-ca',
    'guide-uk',
    'guide-au',
    'guide-starter-package-us',
    'guide-starter-package-uk',
    'guide-starter-package-ca',
    'guide-starter-package-au',
    'guide-select-package-us',
    'guide-select-package-uk',
    'guide-select-package-ca',
    'guide-select-package-au',
    'guide-ultimate-package-us',
    'guide-ultimate-package-uk',
    'guide-ultimate-package-ca',
    'guide-ultimate-package-au',
    ...RENTAL_SLUGS,
  ];

  const autoAddSub = !denylist.includes(bundleSlug);
  let subscriptionProductId;

  if (autoAddSub && subscriptionId) {
    subscriptionProductId = subscriptionId;
  } else if (autoAddSub) {
    subscriptionProductId = await getSubscriptionIdForKronosPackage(
      apolloClient,
      ctClient,
      slug,
      locale,
      currency,
      isCommercetoolsWWWAtcEnabled,
    );
  }

  const packageProductOptionIds =
    productOptionIds && productOptionIds.length > 0
      ? productOptionIds
      : await getProductOptionIdsForKronosPackage(
          apolloClient,
          bundleSlug,
          slug,
          locale,
          currency,
          [...productSelections].concat(warrantySelection ? [warrantySelection] : []),
        );

  if (packageProductOptionIds == null) return;
  const productsBody = upsellIds
    .map(productOptionId => ({ productOptionId, quantity: 1 }))
    .concat(
      autoAddSub ? [{ productOptionId: subscriptionProductId || '', quantity: 1 }] : [],
    );

  const cart = await addItemsToCart(restClient, {
    bundles: [
      {
        bundleId: packageId,
        productOptions: packageProductOptionIds,
        has_trade_in: hasTradeIn,
      },
    ].concat(upsellBundles.map(b => ({ ...b, has_trade_in: false }))),
    products: productsBody,
  });

  return cart;
};

export const addToCartResolver: LocalResolvers<any, any, any> = {
  resolvers: {
    Mutation: {
      addBundleToCart,
      addPackageToCart,
      addProductToCart,
    } as any,
  },
};
